import "@babel/polyfill";

import "normalize.css/normalize.css"; // A modern alternative to CSS resets

import "@/styles/index.scss"; // global css

import plugins from "./plugins"; // plugins

import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

import App from "./App";
import store from "./store";
import router from "./router";
import ElselectTree from "el-select-tree";

import horizontalScroll from "el-table-horizontal-scroll";

import "@/directive/tooltip";
import "@/directive/dragger";

import "@/icons"; // icon
import "./permission"; // permission control
// 剪切板
import VueClipboard from "vue-clipboard2";

// 全局引入global
import global from "@/global/global.js";
Vue.prototype.global = global;

// 富文本组件
import Editor from "@/components/Editor";

Quill.register({
  "modules/htmlEditButton": htmlEditButton,
});

Vue.use(horizontalScroll);
Vue.use(plugins);
Vue.use(VueClipboard);
Vue.use(ElselectTree);
Vue.config.productionTip = false;
Vue.component("Editor", Editor);
new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
