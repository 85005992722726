import router from "./router";
import store from "./store";
import { Message } from "element-ui";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getToken } from "@/utils/auth"; // get token from cookie
import getPageTitle from "./utils/get-page-title";
import { isEmpty } from "lodash-es";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = ["/login"]; // no redirect whitelist
const redirectList = ["/dataDashboard"];

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();

  // set page title
  document.title = getPageTitle(to.meta.title);

  // determine whether the user has logged in
  const hasToken = getToken();
  // router.getMatchedComponents("/refund");

  if (hasToken) {
    if (to.path === "/login") {
      // if is logged in, redirect to the home page
      next({ path: "/" });
      NProgress.done();
    } else {
      if (!store.getters.addRouters.length) {
        store
          .dispatch("user/getUserMenu")
          .then((res) => {
            store.dispatch("GenerateRoutes", { res }).then(() => {
              router.addRoutes(store.getters.addRouters);
              next({ ...to, replace: true });
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        next();
      }
    }
  } else {
    /* has no token*/
    const res = [];
    store.dispatch("ResetRoutes", { res });
    // 清理当前标签
    store.dispatch("tagsView/delAllViews");
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      if (redirectList.indexOf(to.path) !== -1) {
        next(`/login?redirect=${to.path}`);
      } else {
        // next(`/login?redirect=${to.path}`);
        next(`/login`);
      }
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
