import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/indexNew'),
    hidden: true,
  },
  // {
  //     path: '/',
  //     component: Layout,
  //     redirect: '/home',
  //     meta: {
  //         title: '主页',
  //         icon: 'dashboard'
  //     },
  //     children: [{
  //         path: 'home',
  //         name: 'home',
  //         component: () => import('@/views/statistics/userStatistics'),
  //         meta: {
  //             title: '数据统计'
  //         },
  //     }]
  // }

  // {
  //   // path: "/",
  //   component: Layout,
  //   redirect: "/home",
  //   meta: {
  //     title: "主页",
  //     icon: "dashboard",
  //   },
  //   children: [
  //     {
  //       path: "home",
  //       name: "home",
  //       component: () => import("@/views/home"),
  //       meta: {
  //         title: "地图概览",
  //       },
  //     },
  //   ],
  // },

  {
    path: '/',
    component: Layout,
    name: 'statistics',
    redirect: '/statisticsList',
    meta: {
      title: '统计',
      icon: 'tongji',
      noCache: true,
    },
    children: [
      {
        path: '/statisticsList',
        name: 'statisticsList',
        component: () => import('@/views/statistics/userStatistics'),
        meta: {
          title: '首页',
          affix: true,
        },
      },
      // {
      //     path: '/dayRecharge',
      //     name: 'dayRecharge',
      //     component: () => import('@/views/statistics/dayRecharge'),
      //     hidden: true,
      //     meta: {
      //         title: '充值记录',
      //         activeMenu: '/statistics/statisticsList'
      //     }
      // }
    ],
  },
  {
    path: '/dataDashboard',
    component: () => import('@/views/dataDashboard/aggregatedDataDashboard'),
    hidden: true,
    meta: {
      title: '大屏监控',
      icon: 'dashboard',
    },
  },
];

export const asyncRouterMap = [
  {
    path: '/dataDashboard',
    component: () => import('@/views/dataDashboard/aggregatedDataDashboard'),
    meta: {
      title: '大屏监控',
      icon: 'dashboard',
      external: true,
    },
  },
  {
    path: '/stationMonitoring',
    component: Layout,
    meta: {
      title: '安全中心',
      icon: 'dingdan',
      keepAlive: true,
    },
    // redirect: '/rules',
    children: [
      {
        path: '/stationMonitoring/index',
        name: 'stationMonitoringIndex',
        component: () => import('@/views/stationMonitoring/index'),
        meta: {
          title: '站桩监控',
          icon: 'dingdan',
          keepAlive: true,
        },
      },
      {
        path: '/stationMonitoring/detail/:id',
        name: 'stationMonitoringDetail',
        component: () => import('@/views/stationMonitoring/detail'),
        hidden: true,
        meta: {
          title: '监控详情',
          newTab: true,
          keepAlive: true,
        },
      },
      {
        path: '/alarmManagement/index',
        name: 'alarmManagementIndex',
        component: () => import('@/views/alarmManagement/index'),
        meta: {
          title: '告警管理',
          icon: 'dingdan',
          keepAlive: true,
        },
      },
      {
        path: '/alarmManagement/setting',
        name: 'alarmManagementSetting',
        component: () => import('@/views/alarmManagement/setting'),
        meta: {
          title: '告警设置',
          icon: 'dingdan',
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: '/total',
    name: 'total',
    component: Layout,
    meta: {
      title: '经营',
      icon: 'tongji',
      noCache: true,
    },
    // redirect: '/rules',
    children: [
      {
        path: '/totalList',
        name: 'totalList',
        component: () => import('@/views/total/totalList'),
        meta: {
          title: '经营管理',
          icon: 'dingdan',
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: '/device',
    component: Layout,
    name: 'device',
    redirect: 'noRedirect',
    meta: {
      title: '设备管理',
      icon: 'shebei',
    },
    children: [
      {
        path: 'pileList',
        name: 'pileList',
        component: () => import('@/views/device/pileList'),
        meta: {
          title: '电桩管理',
          keepAlive: true,
        },
      },
      {
        path: 'portList',
        name: 'portList',
        component: () => import('@/views/device/portList'),
        hidden: true,
        meta: {
          title: '电枪管理',
        },
      },
      {
        path: 'plotList',
        name: 'plotList',
        component: () => import('@/views/device/plotList'),
        meta: {
          title: '站点管理',
          activeMenu: '/device/plotList',
        },
      },
      {
        path: 'powerList',
        name: 'powerList',
        component: () => import('@/views/device/powerList'),
        meta: {
          title: '电表管理',
          activeMenu: '/device/powerList',
        },
      },
    ],
  },
  {
    path: '/orderlyCharging',
    component: Layout,
    name: 'orderlyCharging',
    meta: {
      title: '有序充电',
      icon: 'shebei',
    },
    children: [
      {
        path: 'monitoring',
        name: 'monitoring',
        component: () => import('@/views/orderlyCharging/monitoring'),
        meta: {
          title: '有序充电监控',
        },
      },
      {
        path: 'linearGradient',
        name: 'linearGradient',
        component: () => import('@/views/device/linearGradient'),
        meta: {
          title: '线路层级设置',
        },
      },
    ],
  },
  {
    path: '/order',
    component: Layout,
    // redirect: '/rules',
    children: [
      {
        path: '/orderList',
        name: 'orderList',
        component: () => import('@/views/order/orderList'),
        meta: {
          title: '订单管理',
          icon: 'dingdan',
          keepAlive: true,
        },
      },
      {
        path: 'orderDetail/:id',
        name: 'orderDetail',
        component: () => import('@/views/order/detail'),
        hidden: true,
        meta: {
          title: '订单详情',
          activeMenu: '/orderList',
          newTab: true,
        },
      },
    ],
  },
  {
    path: '/marketing',
    component: Layout,
    meta: {
      title: '营销中心',
      icon: 'yingxiao',
    },
    children: [
      {
        path: '/member/list',
        name: 'memberList',
        component: () => import('@/views/member/list'),
        meta: {
          title: '会员活动',
        },
      },
      {
        path: '/member/partnerShips',
        name: 'partnerShips',
        component: () => import('@/views/member/partnerShips'),
        hidden: true,
        meta: {
          title: '会员合作商',
        },
      },
      {
        path: '/member/info',
        name: 'memberInfo',
        component: () => import('@/views/member/memberInfo'),
        hidden: true,
        meta: {
          title: '会员信息',
        },
      },
      {
        path: '/member/union',
        name: 'memberUnionAdd',
        component: () => import('@/views/member/unionMember'),
        hidden: true,
        props: () => ({
          mode: 'add',
        }),
        meta: {
          title: '新增联合会员活动',
          keepAlive: true,
        },
      },
      {
        path: '/member/union/:unionId',
        name: 'memberUnionEdit',
        component: () => import('@/views/member/unionMember'),
        hidden: true,
        props: (route) => ({
          id: route.params.unionId,
          mode: 'edit',
        }),
        meta: {
          title: '编辑联合会员活动',
        },
      },
      {
        path: '/member/union/view/:unionId',
        name: 'memberUnionDetail',
        component: () => import('@/views/member/unionMember'),
        hidden: true,
        props: (route) => ({
          id: route.params.unionId,
          mode: 'view',
        }),
        meta: {
          title: '联合会员活动详情',
        },
      },
      {
        path: '/promotionList',
        name: 'promotionList',
        component: () => import('@/views/promotion/promotionList'),
        meta: {
          title: '促销管理',
        },
      },
    ],
  },
  {
    path: '/card',
    component: Layout,
    redirect: '/card/cardList',
    children: [
      {
        path: '/cardList',
        name: 'cardList',
        component: () => import('@/views/card/list'),
        meta: {
          title: 'IC卡管理',
          icon: 'ic_Profiles',
        },
      },
      {
        path: 'useRecods',
        name: 'useRecods',
        component: () => import('@/views/card/useRecods'),
        hidden: true,
        meta: {
          title: '使用记录',
          activeMenu: '/cardList',
        },
      },
    ],
  },
  {
    path: '/rulesmanage',
    component: Layout,
    redirect: 'noRedirect',
    name: 'rulesmanage',
    meta: {
      title: '规则管理',
      icon: 'guize',
    },
    children: [
      {
        path: '/rules/list',
        name: 'rulesList',
        component: () => import('@/views/rules/list'),
        // hidden: true,
        meta: {
          title: '规则列表',
          icon: 'nested',
        },
      },
      {
        path: 'price',
        name: 'price',
        component: () => import('@/views/rules/price/price'),
        hidden: true,
        meta: {
          title: '价格配置',
          activeMenu: '/rules',
        },
      },
    ],
    // children: [
    //   {
    //     path: "/rules",
    //     name: "rules",
    //     component: () => import("@/views/rules/index"),
    //     redirect: "/rules/list",
    //     meta: {
    //       title: "规则列表",
    //       // icon: "nested",
    //     },

    //   },
    // ],
  },
  {
    path: '/registerUser',
    component: Layout,
    redirect: '/registerUser',
    children: [
      {
        path: '/registerUser',
        name: 'registerUser',
        component: () => import('@/views/registerUser/userList'),
        meta: {
          title: '车主档案',
          icon: 'yonghu',
        },
      },
      {
        path: 'carManagement',
        name: 'carManagement',
        component: () => import('@/views/registerUser/carManagement'),
        hidden: true,
        meta: {
          title: '车辆管理',
          activeMenu: '/registerUser',
        },
      },
      {
        path: 'chongzhiRecods',
        name: 'chongzhiRecods',
        component: () => import('@/views/registerUser/chongzhiRecods'),
        hidden: true,
        meta: {
          title: '充值记录',
          activeMenu: '/registerUser',
        },
      },
    ],
  },

  {
    path: '/journal',
    component: Layout,
    redirect: '/journal',
    children: [
      {
        path: '/journal',
        name: 'journal',
        component: () => import('@/views/journalAccount/index'),
        meta: {
          title: '车主账户流水',
          icon: 'caiwu',
        },
      },
    ],
  },

  {
    path: '/notice',
    component: Layout,
    redirect: '/notice',
    children: [
      {
        path: '/notice',
        name: 'notice',
        component: () => import('@/views/notice/list'),
        meta: {
          title: '站点公告',
          icon: 'guize',
        },
      },
    ],
  },

  {
    path: '/notify',
    component: Layout,
    redirect: '/notify',
    children: [
      {
        path: '/notify',
        name: 'notify',
        component: () => import('@/views/notify/notify'),
        meta: {
          title: '消息通知',
          icon: 'guize',
        },
      },
    ],
  },

  {
    path: '/message',
    component: Layout,
    redirect: '/message',
    children: [
      {
        path: '/message',
        name: 'message',
        component: () => import('@/views/message/list'),
        meta: {
          title: '消息中心',
          icon: 'guize',
        },
      },
    ],
  },

  {
    path: '/refund',
    component: Layout,
    redirect: '/refund',
    children: [
      {
        path: '/refund',
        name: 'refund',
        component: () => import('@/views/refund/refundList.vue'),
        meta: {
          title: '提现申请',
          icon: 'yonghu',
        },
      },
    ],
  },
  {
    path: '/warn',
    component: Layout,
    name: 'warn',
    meta: {
      title: '警告',
      icon: 'shebei',
    },
    children: [
      {
        path: 'manage',
        name: 'manage',
        component: () => import('@/views/warn/manage'),
        meta: {
          title: '警告管理',
        },
      },
      {
        path: 'set',
        name: 'set',
        component: () => import('@/views/warn/set'),
        meta: {
          title: '警告设置',
        },
      },
    ],
  },
  {
    path: '/operator',
    component: Layout,
    redirect: '/operator',
    children: [
      {
        path: '/operatorList',
        name: 'operatorList',
        component: () => import('@/views/operator/operatorList'),
        meta: {
          title: '运营商管理',
          icon: 'daili',
        },
      },
      {
        path: 'tixianRecods',
        name: 'tixianRecods',
        component: () => import('@/views/operator/tixianRecods'),
        hidden: true,
        meta: {
          title: '提现记录',
          activeMenu: '/operator',
        },
      },
      {
        path: '/childrenAccount',
        name: 'childrenAccount',
        component: () => import('@/views/operator/childrenAccount'),
        hidden: true,
        meta: {
          title: '运营商详情',
        },
      },
    ],
  },
  {
    path: '/systemUser',
    component: Layout,
    name: 'systemUser',
    // redirect: 'reserveManagement/reserveInfo',
    redirect: 'noRedirect',
    meta: {
      title: '系统管理',
      icon: 'nested',
    },
    children: [
      {
        path: 'role',
        name: 'role',
        component: () => import('@/views/systemUser/roleList'),
        meta: {
          title: '岗位管理',
        },
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('@/views/systemUser/userList'),
        meta: {
          title: '用户管理',
        },
      },
      {
        path: 'menu',
        name: 'menu',
        component: () => import('@/views/systemUser/menuList'),
        meta: {
          title: '菜单管理',
        },
      },
      // ,
      // {
      //     path: 'data',
      //     name: 'data',
      //     component: () => import('@/views/systemUser/userOperators'),
      //     meta: {
      //         title: '数据权限'
      //     }
      // }
    ],
  },
  {
    path: '/feedback',
    component: Layout,
    redirect: '/feedbackList',
    children: [
      {
        path: '/feedbackList',
        name: 'feedbackList',
        component: () => import('@/views/feedback/feedbackList'),
        meta: {
          title: '反馈管理',
          icon: 'fankui',
        },
      },
    ],
  },
  {
    path: '/advert',
    component: Layout,
    redirect: '/advertList',
    children: [
      {
        path: '/advertList',
        name: 'advertList',
        component: () => import('@/views/advert/advertList'),
        meta: {
          title: '广告管理',
          icon: 'guanggao',
        },
      },
    ],
  },
  // {
  //   path: "/promotion",
  //   component: Layout,
  //   redirect: "/promotionList",
  //   children: [
  //     {
  //       path: "/promotionList",
  //       name: "promotionList",
  //       component: () => import("@/views/promotion/promotionList"),
  //       meta: {
  //         title: "促销管理",
  //         icon: "cuxiao",
  //       },
  //     },
  //   ],
  // },
  {
    path: '/finance',
    component: Layout,
    redirect: '/financeList',
    children: [
      {
        path: '/financeList',
        name: 'financeList',
        component: () => import('@/views/finance/financeList'),
        meta: {
          title: '财务管理',
          icon: 'caiwu',
        },
      },
    ],
  },

  {
    path: '/file',
    component: Layout,
    redirect: '/fileList',
    children: [
      {
        path: '/fileList',
        name: 'fileList',
        component: () => import('@/views/file/fileList'),
        meta: {
          title: '文件管理',
          icon: 'caiwu',
        },
      },
    ],
  },
  {
    path: '/miniProgramFile',
    component: Layout,
    redirect: '/miniProgramFile',
    children: [
      {
        path: '/miniProgramFile',
        name: 'miniProgramFile',
        component: () => import('@/views/file/miniProgramFileList'),
        meta: {
          title: '小程序图片',
          icon: 'table',
        },
      },
    ],
  },

  {
    path: '/configList',
    component: Layout,
    redirect: '/configList',
    children: [
      {
        path: '/configList',
        name: 'configList',
        component: () => import('@/views/config/configList'),
        meta: {
          title: '网站配置',
          icon: 'dingdan',
        },
      },
    ],
  },

  {
    path: '/newsList',
    component: Layout,
    redirect: '/newsList',
    children: [
      {
        path: '/newsList',
        name: 'newsList',
        component: () => import('@/views/news/newsList'),
        meta: {
          title: '文章管理',
          icon: 'guize',
        },
      },
    ],
  },
  {
    path: '/accountChildren',
    component: Layout,
    redirect: '/accountChildren',
    children: [
      {
        path: '/accountChildren',
        name: 'accountChildren',
        component: () => import('@/views/accountChildren'),
        meta: {
          title: '分成者管理',
          icon: 'fenchengze',
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
    hidden: true,
  },
];
const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;
