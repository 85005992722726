import cache from "./cache";
import modal from "./modal";
import filtersRegister from "@/plugins/filters";

export default {
  install(Vue) {
    // 缓存对象
    Vue.prototype.$cache = cache;
    // 模态框对象
    Vue.prototype.$modal = modal;
    // 下载文件

    // 注册全局filter
    filtersRegister(Vue);
  },
};
