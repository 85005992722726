const numberFormatter = new Intl.NumberFormat("zh-CN", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  notation: "compact",
  compactDisplay: "short",
});

const filters = {
  number2CNFormatter(num) {
    try {
      // 检查数值大小并转换为万/亿
      if (num >= 1000000) {
        return numberFormatter.format(num);
      } else {
        return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    } catch (error) {
      console.error("Error formatting number:", error);
      return num; // 在异常情况下返回一个错误提示
    }
  },
};
export default (vm) => {
  Object.keys(filters).forEach((key) => {
    vm.filter(key, filters[key]);
  });
};
